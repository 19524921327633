import tracker from "@/tracking/tracker";
import {
  AddToCartEvent,
  ECommerceItem,
  ECommerceItemLocation,
  RemoveFromCartEvent,
  ViewCartEvent,
} from "@/tracking/ga-properties";
import { type Cart } from "@cappelendamm/e-commerce-services";

const getCategoryFromPath = (path: string) => {
  const [_pim, category, ..._rest] = path.split("/");
  return category;
};

export const trackCartEvent = (
  cart: Cart,
  eventName: "add_to_cart" | "remove_from_cart" | "view_cart",
  location?: ECommerceItemLocation,
): void => {
  const event: AddToCartEvent | RemoveFromCartEvent | ViewCartEvent = {
    eventName,
    ecommerce: {
      currency: cart.total.currency,
      value: cart.total.net,
      items: cart.items.map<ECommerceItem>(
        (
          {
            name,
            price,
            quantity,
            variant: {
              sku,
              product: { id, path },
            },
          },
          index,
        ) => ({
          index,
          quantity,
          item_id: id,
          item_name: name,
          item_variant: sku,
          item_category: getCategoryFromPath(path),
          price: price.gross,
          ...location,
        }),
      ),
    },
  };
  tracker.trackEvent(event);
};
