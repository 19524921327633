"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { trackCartEvent } from "./tracker";
import { cart } from "@/cart";
import { Plus, Minus, Trash } from "@phosphor-icons/react/dist/ssr";
import classNames from "classnames";
import { Cart } from "@cappelendamm/e-commerce-services";
import { PiShoppingCart, PiX } from "react-icons/pi";
import { MenuNavigation, MenuTop } from "@/components/Header/MenuState";

export const HeaderCart = ({ cartLayout }: { cartLayout?: Cart }) => {
  const [cartData, setCartData] = useState<Cart>();
  const [addedToCartModal, setAddedToCartModal] = useState(false);
  const [parentMenuState, setParentMenuState] = useState(false);

  const numberOfItems = (cart: Cart) =>
    cart.items.reduce((acc, curr) => acc + curr.quantity, 0);

  useEffect(() => {
    const getCartData = async () => {
      if (cartLayout) {
        return setCartData(cartLayout);
      }
      const cartFunction = await cart();
      const cartData = await cartFunction.get();
      return setCartData(cartData);
    };
    getCartData();
  }, [cartLayout]);

  useEffect(() => {
    if (
      cartLayout &&
      cartData &&
      !parentMenuState &&
      numberOfItems(cartData) < numberOfItems(cartLayout)
    ) {
      setAddedToCartModal(true);
      setTimeout(() => {
        setAddedToCartModal(false);
      }, 3000);
    }
  }, [cartLayout, cartData]);

  const quantity = async (
    action: "increase" | "decrease" | "remove",
    item: Cart["items"][0],
  ) => {
    const { increaseQuantity, decreaseQuantity, removeItem } = await cart();
    switch (action) {
      case "increase":
        const data = await increaseQuantity({
          sku: item.variant.sku,
        });
        trackCartEvent(data, "add_to_cart", {
          item_list_name: "product_page_scroll_box",
        });
        setCartData(data);
        break;
      case "decrease":
        const decreasedCart = await decreaseQuantity({
          sku: item.variant.sku,
        });
        trackCartEvent(decreasedCart, "remove_from_cart", {
          item_list_name: "product_page_scroll_box",
        });
        setCartData(decreasedCart);
        break;
      case "remove":
        const removedCart = await removeItem({
          sku: item.variant.sku,
        });
        trackCartEvent(removedCart, "remove_from_cart", {
          item_list_name: "product_page_scroll_box",
        });
        setCartData(removedCart);
        break;
    }
  };

  if (!cartData) return null;

  return (
    <div>
      {addedToCartModal ? (
        <div className="fixed bottom-0 left-0 z-50 w-full p-6 sm:absolute sm:bottom-auto sm:left-auto sm:right-0 sm:top-8 sm:w-fit">
          <div className="relative flex h-fit w-full flex-col gap-4 rounded border bg-white p-6">
            <button
              className="absolute right-2 top-2"
              onClick={() => setAddedToCartModal(false)}
            >
              <PiX className="inline" size={24} />
            </button>
            <span>Varen er lagt i handlekurv</span>
            Totalt{" "}
            {numberOfItems(cartData) ? ` ${numberOfItems(cartData)}` : ""} vare
            <button
              onClick={() => setParentMenuState(true)}
              className={classNames(
                "w-full rounded-full bg-[#9A4234] px-4 py-3 text-center text-[white] hover:bg-[#733227] sm:w-[375px]",
              )}
            >
              Se handlekurv
            </button>
          </div>
        </div>
      ) : null}
      <MenuNavigation
        parentMenuState={parentMenuState}
        className="absolute left-0 top-0 h-screen w-screen overflow-hidden"
        icon={
          <div
            className="relative text-sm"
            onClick={() => setParentMenuState(true)}
          >
            <PiShoppingCart className="inline" size={24} />
            <span className="sr-only">{`Se handlevogn - ${numberOfItems(cartData)} vare${numberOfItems(cartData) > 1 ? "r" : ""}`}</span>
            {numberOfItems(cartData) > 0 && (
              <span
                aria-hidden="true"
                className="absolute bottom-[-4px] right-[-8px] h-5 w-5 items-center justify-center rounded-full bg-[#9A4234] text-white"
              >
                {numberOfItems(cartData)}
              </span>
            )}
          </div>
        }
      >
        <div className="absolute right-0 z-[51] h-full w-full sm:w-[375px]">
          <div className="animation-slide-from-right absolute right-0 top-0 h-screen w-full overflow-scroll bg-white sm:w-[375px]">
            <div className="hidden-scrollbar sm:show-scrollbar h-[70%] overflow-y-scroll sm:h-[calc(100%-100px)]">
              <h2 className="relative px-4 py-3 text-center text-2xl">
                Handlekurv
                <button
                  className="absolute right-4"
                  aria-label="Lukk Modal"
                  type="button"
                >
                  <MenuTop>
                    <div onClick={() => setParentMenuState(false)}>
                      <PiX className="inline" size={24} />
                    </div>
                  </MenuTop>
                </button>
              </h2>
              {cartData.items.length > 0 ? (
                <>
                  <div className="mx-4 rounded border py-4">
                    {cartData.items.map((item, index: number) => (
                      <div
                        className={classNames(
                          "relative mx-4 flex flex-col gap-3 border-b py-4",
                          {
                            "border-none": cartData.items.length - 1 === index,
                          },
                        )}
                        key={item.name}
                      >
                        <div className="flex items-center justify-center gap-2">
                          <Image
                            className="rounded"
                            src={item.images?.[0]?.url || ""}
                            height={200}
                            width={84}
                            alt="Bok bilde"
                          />
                          <div className="w-full">
                            <div>{item.name}</div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="flex w-fit items-center justify-center gap-4 rounded-full border px-2 py-1">
                            {item.quantity > 1 ? (
                              <button
                                className="rounded px-1 hover:bg-[#F2EFF0]"
                                type="button"
                                onClick={async () => {
                                  await quantity("decrease", item);
                                }}
                              >
                                <Minus size={24} />
                              </button>
                            ) : (
                              <button
                                className="rounded px-1 hover:bg-[#F2EFF0]"
                                type="button"
                                onClick={async () => {
                                  await quantity("remove", item);
                                }}
                              >
                                <Trash size={24} />
                              </button>
                            )}
                            <div>{item.quantity}</div>
                            <button
                              className="rounded px-0.5 hover:bg-[#F2EFF0]"
                              type="button"
                              onClick={async () => {
                                await quantity("increase", item);
                              }}
                            >
                              <Plus size={24} />
                            </button>
                          </div>
                          <span className="ml-auto">{`${item.price.net},–`}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="sticky bottom-0 flex h-[30%] w-full flex-col gap-4 border-t bg-white px-10 py-4 sm:fixed sm:h-auto sm:w-[375px]">
                    <div className="flex font-semibold">
                      <span>Totalsum</span>
                      <span className="ml-auto">{cartData.total.net},–</span>
                    </div>
                    <a
                      onClick={() => setParentMenuState(false)}
                      href={"/utsjekk"}
                      className={classNames(
                        "w-full rounded-full bg-[#9A4234] px-4 py-3 text-center text-[white] hover:bg-[#733227]",
                      )}
                    >
                      Gå til kassen
                    </a>
                  </div>
                </>
              ) : (
                <div className="mx-4 py-4 text-center">
                  <h2 className="heading-02">Handlekurven er tom</h2>
                  <span>Du har ingen produkter i handlekurven</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </MenuNavigation>
    </div>
  );
};
